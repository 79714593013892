/* eslint-disable jsx-a11y/iframe-has-title */
// import React, { useState} from 'react'

import "../App.css";

import floriane from "../assets/images/floriane-vita-FyD3OWBuXnY-unsplash.jpg";
import ReCAPTCHA from "react-google-recaptcha";
const Contact = () => {
  return (
    <>
      <div className="contact-wraper">
        <div className="container">
          <h4 className="text-center py-3">Get in Touch</h4>
          <div className="row forms ">
            <div className="col-12 col-md-7 col-xxl-6">
              <div className="wrap-element ">
                {/* <img src={floriane} className="img-fluid" alt="floriane" /> */}
                <iframe class="wrapped-iframe"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.759721826159!2d78.44673251487681!3d17.423314988057083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfb82c1dc351704be!2zMTfCsDI1JzIzLjkiTiA3OMKwMjYnNTYuMSJF!5e0!3m2!1sen!2sin!4v1658401241774!5m2!1sen!2sin"
                  allowfullscreen alt={floriane}></iframe>
              </div>
            </div>
            <div className="col-12 col-md-5 col-xxl-6">
              <form action="mailto.php" method="POST">
                <div className="form-group pb-2">
                  <input
                    type="text"
                    className="form"
                    id="username"
                    aria-describedby="emailHelp"
                    placeholder="Name"
                    name="username"
                    autoComplete="off"
                    min="3"
                    max="250"
                    required
                  />
                </div>
                <div className="form-group pb-2">
                  <input
                    type="number"
                    className="form"
                    id="exampleInputNumber1"
                    aria-describedby="emailHelp"
                    placeholder="Phone Number"
                    name="phone"
                    autoComplete="off"
                    required
                    min="3"
                    max="250"
                  />
                </div>
                <div className="form-group pb-2">
                  <input
                    type="email"
                    className="form"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Email"
                    name="email"
                    required
                  />
                </div>
                <div className="form-group pb-3">
                  <textarea
                    className="form"
                    id="exampleFormControlTextarea1"
                    rows="4"
                    placeholder="Message"
                    name="msg"
                    autoComplete="off"
                    required
                  ></textarea>
                </div>
                <div className="recaptcha pb-3">
                  <ReCAPTCHA
                    sitekey="6LeXXUseAAAAAH7WE9NmMva6mFrP3_7BElrG_tVS"
                    required
                  />
                </div>
                {/* <button  className="btn bg-white btn-block text-info"  id="id123-button-send " value="submit">
                    <b>Sumbit</b>
                  </button> */}
                {/* <input type="submit" className="button_example mt-3"
                  id="id123-button-send " value="Submit" /> */}
                <input type="submit" value="Submit" className="mt-3" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
