import React from "react";
import cs from "../assets/images/comingsoon.png";
import gl from "../assets/images/GDHAlogo.png";
import "../App.css";
const Gdha = () => {
  return (
    <div>
      <div className="container row ">
        
        <div className="col-12 col-md-4 col-xxl-6 m-5 ">
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
        <div className="col-12 col-md-6 col-xxl-6  ">

          <img src={gl} className="img-fluid"max-width="100%" alt="gallery" />
          <br></br><br></br><br></br>
          <span className="m-5">   <img src={cs} className="img-fluid" alt="gallery" /> </span>
        </div>
      </div>
    </div>
  );
};

export default Gdha;
