import React, { useState } from "react";
// import { Link } from "react-router-dom"
import ProductApi from "./ProductApi";
// import ProductApi1 from "./ProductApi1";
import ProductApi2 from "./productApi2";
// import pdf from "../assets/images/Addy4-01-converted (1).pdf"
const Product = () => {
  const [Prodcuts] = useState(ProductApi)
  // const [product1] = useState(ProductApi1)
  const [Product2] = useState(ProductApi2)
  // var brk= curElem.split('~');
  // var res = brk.join("<br></br>")
  return (
    <>
      <div className="container py-5">
        <h2 className="text-center">Our Products</h2>
        <hr className="w-25 mx-auto mb-5" />

        {/* addy4 mask and nipuna suryanarayan products */}
        <div className="row">
          {Prodcuts.map((curElem) => {         
            return (
              <>
                <div className="col-12 col-md-4 col-lg-3 col-xxl-3 mt-4 products">
                  <div className="card">
                   <img className="card-img-top" src={curElem.image} alt="Card" />
                    <div className="card-body">
                      <a target="_blank" href={curElem.link} rel="noreferrer" >
                      <span><h6 className="card-title">{curElem.title1}<br></br>{curElem.title2} </h6></span>
                      </a>
                      
                      <p className="card-text text-justify">
                        {curElem.description}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </div>
        {/* <div className="mt-4">
          <Link className="" to={pdf} target="blank"> View Detailed PDF </Link>
        </div> */}

 {/* oil products */}

        <div className="row">
          {Product2.map((curElem) => {
            return (
              <>
                <div className="col-12 col-md-4 col-lg-3 col-xxl-3 mt-4">
                  <div className="card">
                 <img className="card-img-top" src={curElem.image} alt="Card" />
                    <div className="card-body">
                      <a target="_blank" href={curElem.link} rel="noreferrer" >
                      <h5 className="card-title">{curElem.title}</h5>
                      </a>
                      <p className="card-text text-justify">
                        {curElem.description}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </div>
    


        {/* dhoops products */}
        {/* <div className="row mt-4">
          {product1.map((cules) => (
            <>
              <div className="col-12  col-md-4 col-xl-3 col-xxl-3 mb-4 dhoops">
                <div className="card">
                  <img className="card-img-top" src={cules.image} alt="sangam" />
                  <div className="card-body">
                    <h5 className="card-title text-center ">{cules.title}</h5>
                    <p className="card-text">{cules.price}</p>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div> */}
      </div>
    </>

  );
};

export default Product;
