 
const ProductApi2 = [
   
   
    // {
    //   image: "images/DHA headshot oil img.jpg",
    //   title1: "DHA® Head Shot Oil",
    //   link: "http://www.nh9corp.com/dha/",
    //   description:
    //     "This headache relief oil is made up of natural ingredients like sesame, triphala, ajwain, castor oil and many more. All the ingredients are known for their relieving properties and free from harmful chemicals to make you feel better. Works best when massaged at the pain prone area. Say no popping pills and yes to HeadShot Oil.",
    // },
  ];
  
  
  export default ProductApi2