import React from "react";
import cs from "../assets/images/comingsoon.png";

import "../App.css";
const Career = () => {
  return (
    <div>
      <div className="container-fluid bg-overlay">
        <div className="container">
          <img src={cs} className="img-fluid" alt="Career " />
        </div>
      </div>
    </div>
  );
};

export default Career ;
