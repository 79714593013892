/* eslint-disable jsx-a11y/no-distracting-elements */
import React from "react";
import neomask from "../assets/images/neo-mask.jpg";

import Bhujiya from "../assets/images/bhujia.jpg";
import dhenum from "../assets/images/Surayanarayan.jpg";
import Sanitary from "../assets/images/Sanitary pad banner.jpg";
import allinone from "../assets/images/allinone oil.jpg";
import Headshot from "../assets/images/Headshot oil.jpg";
import Nipurna from "../assets/images/Nipurna hair oil.jpg";
import left from "../assets/images/Left-Arrow.png";
import right from "../assets/images/Right-Arrow.png";
const Header = () => {
  return (
    <>
      <marquee className="mt-2 mb-0 "  >
        <h6>
          {" "}
          NH9 Corp® is celebrating Silver jubliee year 2022-23
          {/* , it ensures fulfilling
          the needs of those looking for bhujia & namkeens, masks,
           dhoop, ayurvedic oils and sanitary napkins. */}
        </h6>
      </marquee>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <a href="http://www.nh9corp.com/addy4/">
              <img className="d-block w-100" src={neomask} alt="First slide" />
            </a>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={dhenum} alt="Secound slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={Bhujiya} alt="Third slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={Sanitary} alt="four slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={allinone} alt="five slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={Headshot} alt="six slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={Nipurna} alt="seven slide" />
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <img src={left} className="img-fluid" alt="Left-arrow" />
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <img src={right} className="img-fluid" alt="Left-arrow" />
        </a>
      </div>
    </>
  );
};

export default Header;
