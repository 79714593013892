import { Route, Switch } from "react-router-dom";
import "./App.css";
import About from "./component/About";
import Gallery from "./component/Gallery";
import Gdha from "./component/Gdha";
import Career from "./component/Career";
import Contact from "./component/Contact";
import Footer from "./component/Footer";
import Navbar from "./component/Navbar";
import Product from "./component/Product";
import ScrollToTop from "./ScrollToTop";
import Home from "./Home";

import { useState, useEffect } from "react";
import Popup from "./component/Popup";
import pop from "./assets/images/jubli.jpg";
function App() {
  const [isOpen, setIsOpen] = useState(true);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(!isOpen);
    }, 8000);
  }, []);

  return (
    <>
      {isOpen && (
        <Popup
          content={
            <>
              <img
                className="popupimage image-fluid"
                src={pop}
                alt="NH9 Corp® is celebrating Silver jubliee year 2022-23"
              ></img>
            </>
          }
          handleClose={togglePopup}
        />
      )}

      <Navbar />
      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/product">
            <Product />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/gdha">
            <Gdha />
          </Route>
          <Route path="/gallery">
            <Gallery />
          </Route>
          <Route path="/career">
            <Career />
          </Route>
        </Switch>
      </ScrollToTop>
      <Footer />
    </>
  );
}

export default App;
