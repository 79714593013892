const ProductApi = [
  {
    image: "images/neo-mask.jpg",
    title1: "Addy@4®",
    title2: "The Social Vaccine®",
    link: "http://www.nh9corp.com/addy4/",
    description:
      "NH9 Corp® provides NeoMask that safeguards against spread of coronavirus and protects from pollutants and pathogens. Made from 95% polyester and 5% spandex, it gives you a snug and perfect fit hat cover your nose, mouth, and chin and does not impair breathing, voice.",
  },
  {
    image: "images/neo-kids.jpg",
    title1: "Neo Kids Masks™",
    link: "http://www.nh9corp.com/neokidsmask/",
    description:
      "Neo Kids contains the masks designed especially for kids. These come in two different designs i.e. surgical mask and butterfly shaped mask with varieties of colours to choose from. These are made with comfortable and super breathable material & protect from germs and pollutants.",
  },
  {
    image: "images/Kelgerm.jpg",
    title1: "Addy@4® Sanitizer",
    link: "http://www.nh9corp.com/sanitizer",
    description:
      "Addy@4 Sanitizer - Addy@4 Sanitizer is the ultimate protector from germs, bacteria and viruses. It is made with the power of six natural ingredients like neem, tulsi, lemon and more. It comes in nine magical frangrances.",
  },
  {
    image: "images/nipuna.jpg",
    title1: "Nipuna®",
    link: "http://www.nh9corp.com/nipuna/",
    description:
      "NIPUNA* : The Health Expert that ensures hygiene, safety, good health and worry-free chums. We offer superior and comfortable protection experience to give young girls and women peace of mind  It has a gel-lock technology which locks upto 100% wetness, odor & germs. ",
  },
  {
    image: "images/dhoop.jpg",
    title1: "Suryanarayan®",
    link: "http://www.nh9corp.com/suryanarayan/",
    description:
      "This Dhoop Sticks is 100% natural, It is made from Desi Cow Dung & Hawan Samagri. This Dhoop Sticks give almost all benefits doing Agnihotra Sacrifice. Help you to create an aesthetic & spiritual environment around and Keeps insect / Mosquito  ",
  },
  {
    image: "images/bhujia.jpg",
    title1: "Kotgate™ Bhujia ",
    link: "http://www.nh9corp.com/kotgate/",
    description:
      "We are the providers of authentic Bhujia made out of the best lentils and tasty spices complimenting your taste buds. We supply the superior quality Bhujia at a very affordable pricing to the businesses who want to buy in bulk quantity. Reduce costs with direct sourcing ",
  },
  {
    image: "images/Hairoil img.jpg",
    title1: "Nipuna® ",
    title2: "Hair Therapist Expert ",
    link: "http://www.nh9corp.com/nipuna/",
    description:
      "Give your hair the best expert care with natural beauty ingredients like coconut oil, bhringraj, jojoba oil, lavender oil and more. Nipuna Hair Oil is free from chemical additives and made with herbs to become one solution for all hair problems. It strengthens your hair and gives them a nourished shine.",
  },
  {
    image: "images/DHA one for all img.jpg",
    title1: "DHA® ",
    title2: "Allow us to help you Achive...®...Relief from All Pains ",
    link: "http://www.nh9corp.com/dha/",
    description:
      // "This rich ayurvedic oil is infused with the healing properties of sesame, alsi, triphala, mahua oil and many more natural and non toxic herbs to treat pains of all kinds. Massage at the pain ridden area and free yourself from any kind of joints and muscular pain. All in One oil heals and repairs tissues, joints and reduces arthritis pain.",
      // "This rich ayurvedic oil is infused with the healing properties of sesame, alsi, triphala, mahua oil and many more natural and non toxic herbs to treat pains of all kinds. Massage at the pain ridden area and free yourself from any kind of muscular pain and headache. All the ingredients are known for their relieving properties and free from harmful chemicals to make you feel better.",
      "DHA oils are infused with the healing properties of sesame, triphala, ajwain, castor oil and more natural and non toxic herbs to treat pains of all kinds. Massage at the pain ridden area and free yourself from any kind of joints, muscular pain and headache.",
  },
];

export default ProductApi;
