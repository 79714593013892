/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import gallary from "../assets/images/img 1.png"
import gallary1 from "../assets/images/img 2.png"
import gallary6 from "../assets/images/img 3.png"
import gallary3 from "../assets/images/img 4.png"
import gallary4 from "../assets/images/img 5.png"
import gallary5 from "../assets/images/img 6.png"
import gallary2 from "../assets/images/img 7.png"


import "../App.css";
const About = () => {
  return (
    <div>
      <div className="container-fluid bg-overlay">
        <div className="container">
          <div className="top_heading">
            <h1>M/S NH9 CORP<sup>®</sup> </h1>
            
          </div>
          <p className="despite">Founded in 1998 by Avinash Harsh, is an integrated company based out of Hyderabad where we don’t sell, we make it available for you at your disposal. Do you have eyeballs for both safety and style together! Do you want great authentic snacks and hygienic products all under one roof ! WE ARE HERE FOR YOU.<br></br> NH9 Corp<sup>®</sup> ensures fulfilling the needs of those who are looking for neo & surgical masks, kundan jewellery, sanitary napkins, Bikaneri bhujia and incense sticks and dhoop. With a portfolio of around 8000 products, we are not confined to national boundaries but have a strong presence in international markets.</p>
        </div>
      </div>
      <div className='container'>
        <div className="Aboutus_wrap">
          <h1 className="d-flex align-items-center"><hr  /> About Us <hr /></h1>
          {/* <p className="text-center">Despite taking precaution many
            work places are still facing covid cases
            and in these, adverse circumstances PPE
            can be the difference between life and death</p> */}
        </div>


        <div className="row aboutus_wrap_outer">
          <div className="col-12 col-md-6 col-lg-6 col-xxl-6 mt-4">
            <h4>Mask</h4>
            <p>NH9 Corp<sup>®</sup> provides NeoMask that safeguards against spread of coronavirus and protects from pollutants and pathogens. Made from 95% polyester and 5% spandex, it gives you a snug and perfect fit hat cover your nose, mouth, and chin and does not impair breathing, voice.</p>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xxl-6 mt-4">
            <h4>SURGICAL MASK</h4>
            <p>We provide surgical masks, also called medical face masks as it is generally worn by medical and health professionals as a protective shield against germs and prevent from any transmission of infections. It became our non-forgettable thing during the pandemic.</p>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xxl-6 mt-4">
            <h4>PREMIUM DHOOP STICK</h4>
            <p>This Dhoop Sticks is 100% natural, It is made from Desi Cow Dung & Hawan Samagri. This Dhoop Sticks give almost all benefits doing Agnihotra Sacrifice. Help you to create an aesthetic & spiritual environment around and Keeps insect / Mosquito away</p>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xxl-6 mt-4">
            <h4>BHUJIA</h4>
            <p>We are the providers of authentic Bhujia made out of the best lentils and tasty spices complimenting your taste buds. We supply the superior quality Bhujia at a very affordable pricing to the businesses who want to buy in bulk quantity. Reduce costs with direct sourcing</p>
          </div>
        </div>
      </div>

      <div className="container">
       
      <div className="presentTion_outer">
      <div className="presentTion_inner">
        <img src={gallary} className="img-fluid" alt="gallary" />
      </div>
      <div className="presentTion_inner">
        <img src={gallary1} className="img-fluid" alt="gallary" />
      </div>
      <div className="presentTion_inner">
        <img src={gallary2} className="img-fluid" alt="gallary" />
      </div>
      


    </div>
    <div className="presentTion_outer_wrap">
     <div className="presentTion_inner_wrap">
     <img src={gallary5} className="img-fluid" alt="gallary" />
     </div>
     <div className="presentTion_inner_wrap">
     <img src={gallary6} className="img-fluid" alt="gallary" />
     </div>
     <div className="presentTion_inner_wrap">
     <img src={gallary3} className="img-fluid" alt="gallary" />
     </div>
     <div className="presentTion_inner_wrap">
     <img src={gallary4} className="img-fluid" alt="gallary" />
     </div>
    </div>
  
      </div>
      <div className="container">
        <div className="row twenty_wrap">
          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
            <h1>19<br /><span>98</span></h1>
          </div> 
          <div className="col-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
            <h4>How It All Started</h4>
            <div id="summary">
              <p className="collapse" id="collapseSummary">
                M/S NH9 CORP® founded in 1998 by Avinash Harsh, is an integrated company based out of Hyderabad where we don’t sell, we make it available for you at your disposal.
                Do you have eyeballs for both safety and style together! Do you want great authentic snacks and hygienic products all under one roof ! WE ARE HERE FOR YOU.
                NH9 Corp® ensures fulfilling the needs of those who are looking for neo & surgical masks, kundan jewellery, sanitary napkins, Bikaneri bhujia and incense sticks and dhoop.
                With a portfolio of around 8000 products, we are not confined to national boundaries but have a strong presence in international markets.
                After growing manifold in terms of volumes and revenues and gaining a handful of experience of 24+ years , we are expanding our business hands to Education, manufacturing and going to be labelled as a Private Limited company.
                We have earned a number of trademarks and copyrights out of which 3 has been registered with Intellectual Property NH9 CORP® & OSCORP (Class 36, 39, 35 and 43) respectively in 2018.
               <br/>
               <span>
               Standing tall as a 5th generation Harsh family, our proprietor Avinash Harsh hails from  Rajasthan.  Right from serving the Royals to the Navy of the country and starting from the nooks of small towns to expanding to international boundaries, the whole Harsh family holds diverse backgrounds in terms of their businesses and services.
               Udhodasji Harsh, the Great Great Grandfather, served the Royal family of Bikaner.
               Ram Narayanji Harsh, the  Great Grandfather had the business of matchbox importing from England and trading of Bhujia and other dairy & perishable products in Bikaner which became their family business. He was blessed with 4 sons.
               1)  Hari Narayan Harsh (eldest son) was the jury member of Jury for Rajasthan Court and also handled the family business.
               2)  Chaganlal Harsh (2nd eldest son) was into spiritualism.
               3)  Jai Narayan Harsh (2nd youngest son) was a  professional dyeing master as well as a visiting consultant in the printing and packaging industries of Bikaner. He also continued the family trading business.
               4)  Laxmi Narayan Harsh (youngest son)  pursued LLB and got into Government Services at Dum Dum Airport Calcutta. Also continued the family business in Calcutta.

               Asharam Harsh was the only son of Hari Narayan Harsh, who handled the family trading business.
               Jai Narayan Harsh was blessed with two sons-
               1)  Surya Narayan Harsh took care of the family business.
               2)  Bajrang Lal Harsh served as a Sailor in the Navy through NCC and also Birla Group.Then he continued the family trading business with his son and the proprietor Avinash Harsh at Sirpur-Kaghaznagar, Andhra pradesh.
               Avinash Harsh himself holds a versatile experience of serving the Banking & Financial industry for 16 long years . Now he is continuing the  family business  in  Telangana, Hyderabad and also expanded to other locations like Mumbai, Ranchi, Jamshedpur, Bengaluru, Delhi, Chandigarh, Kishangarh, Nagpur, Vijayawada.
  
              
              </span>
              </p>
              <a className="collapsed" data-toggle="collapse" href="#collapseSummary" aria-expanded="false" aria-controls="collapseSummary"></a>
            </div>
          </div>
        </div>
      </div>

      <div className="container OUR_PRODUCTS">
      {/* <h2>OUR PRODUCTS</h2>
      <hr className="w-25 mx-auto mb-5"/> */}
      </div>
    </div>
  )
}

export default About
