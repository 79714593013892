import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import StartupLogo from "../assets/images/StartupLogo.jpg";
import DigitalLogo from "../assets/images/DigitalLogo.jpg";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container pt-5">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3 col-xxl-4">
              <img src={logo} className="img-fluid mb-4" alt="logo" />
              <p><br></br>
                <sub>
                  <b>Disclaimer : </b>
                  For illustration purpose only 
                  The photograph and actual product may differ in appearance 
                  All Logos, trademarks or Registered trademarks are property of Avinash Harsh 
                  Any infringement of said Trademarks, Logos and Copyrights are punishable offence under law <br></br>
                  <b>*Nipuna</b> is the word mark of Avinash Harsh<br></br>
                 <b>&#9400;</b> - 1998-2022 All rights reserved
                </sub>
              </p>
            </div>
            <div className="col-12 col-md-4 col-lg-1 col-xxl-4">
              <br></br><p>.</p>
            </div>
            <div className="col-12 col-md-4 col-lg-2  col-xxl-4">
              <h4 className="mb-4">Company</h4>
              <p>
                <Link to="/">Home</Link>
              </p>
              <p>
                <Link to="/about">About Us</Link>
              </p>
              <p>
                <Link to="/product">Products</Link>
              </p>
              <p>
                <Link to="/gdha">GDHA Foundation<sup>®</sup></Link>
              </p>
              <p>
                <Link to="/gallery">Gallery</Link>
              </p>
              <p>
                <Link to="/career">Career</Link>
              </p>
              <p>
                <Link to="/contact">Contact</Link>
              </p>
            </div>
            
            <div className="col-12 col-md-4 col-lg-3 col-xxl-4">
              <h4 className="mb-4">Contact Us</h4>
              <div>
                <p>
                  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <title />
                    <g data-name="1" id="_1">
                      <path d="M348.73,450.06a198.63,198.63,0,0,1-46.4-5.85c-52.43-12.65-106.42-44.74-152-90.36s-77.71-99.62-90.36-152C46.65,146.75,56.15,99.61,86.69,69.07l8.72-8.72a42.2,42.2,0,0,1,59.62,0l50.11,50.1a42.18,42.18,0,0,1,0,59.62l-29.6,29.59c14.19,24.9,33.49,49.82,56.3,72.63s47.75,42.12,72.64,56.31L334.07,299a42.15,42.15,0,0,1,59.62,0l50.1,50.1a42.16,42.16,0,0,1,0,59.61l-8.73,8.72C413.53,439,383.73,450.06,348.73,450.06ZM125.22,78a12,12,0,0,0-8.59,3.56l-8.73,8.72c-22.87,22.87-29.55,60-18.81,104.49,11.37,47.13,40.64,96.1,82.41,137.86s90.73,71,137.87,82.41c44.5,10.74,81.61,4.06,104.48-18.81l8.72-8.72a12.16,12.16,0,0,0,0-17.19l-50.09-50.1a12.16,12.16,0,0,0-17.19,0l-37.51,37.51a15,15,0,0,1-17.5,2.72c-30.75-15.9-61.75-39.05-89.65-66.95s-51-58.88-66.94-89.63a15,15,0,0,1,2.71-17.5l37.52-37.51a12.16,12.16,0,0,0,0-17.19l-50.1-50.11A12.07,12.07,0,0,0,125.22,78Z" />
                      <path d="M364.75,269.73a15,15,0,0,1-15-15,99.37,99.37,0,0,0-99.25-99.26,15,15,0,0,1,0-30c71.27,0,129.25,58,129.25,129.26A15,15,0,0,1,364.75,269.73Z" />
                      <path d="M428.15,269.73a15,15,0,0,1-15-15c0-89.69-73-162.66-162.65-162.66a15,15,0,0,1,0-30c106.23,0,192.65,86.43,192.65,192.66A15,15,0,0,1,428.15,269.73Z" />
                    </g>
                  </svg>
                  <a className="ml-2" href="tel:+917669861006">
                    +91 7669861006
                  </a>{" "}
                </p>
                <p>
                  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <title />
                    <g data-name="8-Email" id="_8-Email">
                      <path d="M45,7H3a3,3,0,0,0-3,3V38a3,3,0,0,0,3,3H45a3,3,0,0,0,3-3V10A3,3,0,0,0,45,7Zm-.64,2L24,24.74,3.64,9ZM2,37.59V10.26L17.41,22.17ZM3.41,39,19,23.41l4.38,3.39a1,1,0,0,0,1.22,0L29,23.41,44.59,39ZM46,37.59,30.59,22.17,46,10.26Z" />
                    </g>
                  </svg>
                  <span className="ml-2"><a href="mailto:care@nh9corp.com">care@nh9corp.com</a></span>{" "}
                </p>
                <p className="d-flex">
                  <svg
                    viewBox="0 0 100.353 100.352"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path d="M58.23,69.992l14.993-24.108c0.049-0.078,0.09-0.16,0.122-0.245c2.589-4.222,3.956-9.045,3.956-13.969   c0-14.772-12.018-26.79-26.79-26.79S23.72,16.898,23.72,31.67c0,4.925,1.369,9.75,3.96,13.975c0.03,0.074,0.065,0.146,0.107,0.216   l14.455,24.191c-11.221,1.586-18.6,6.2-18.6,11.797c0,6.935,11.785,12.366,26.829,12.366S77.3,88.783,77.3,81.849   C77.301,76.226,69.578,71.509,58.23,69.992z M30.373,44.294c-2.39-3.804-3.653-8.169-3.653-12.624   c0-13.118,10.672-23.79,23.791-23.79c13.118,0,23.79,10.672,23.79,23.79c0,4.457-1.263,8.822-3.652,12.624   c-0.05,0.08-0.091,0.163-0.124,0.249L54.685,70.01c-0.238,0.365-0.285,0.448-0.576,0.926l-4,6.432L30.507,44.564   C30.472,44.471,30.427,44.38,30.373,44.294z M50.472,91.215c-14.043,0-23.829-4.937-23.829-9.366c0-4.02,7.37-7.808,17.283-8.981   l4.87,8.151c0.269,0.449,0.751,0.726,1.274,0.73c0.004,0,0.009,0,0.013,0c0.518,0,1-0.268,1.274-0.708l5.12-8.232   C66.548,73.9,74.3,77.784,74.3,81.849C74.301,86.279,64.515,91.215,50.472,91.215z" />
                      <path d="M60.213,31.67c0-5.371-4.37-9.741-9.741-9.741s-9.741,4.37-9.741,9.741s4.37,9.741,9.741,9.741   C55.843,41.411,60.213,37.041,60.213,31.67z M43.731,31.67c0-3.717,3.024-6.741,6.741-6.741s6.741,3.024,6.741,6.741   s-3.023,6.741-6.741,6.741S43.731,35.387,43.731,31.67z" />
                    </g>
                  </svg>{" "}
                  <span className="ml-2">
                    {" "}
                    Regus, Business Center, Midtown <br /> Building, Banjara
                    Hills, Hyderabad, <br /> Telangana, India
                  </span>
                </p>
              </div>
              <br></br>
              <div className="icon-footer mb-2">
                <a href="https://www.facebook.com/nh9corp" target="blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/nhninecorp/" target="blank" >
                  <i className="fab fa-instagram insta"></i>
                </a>
                <a href="https://wa.me/+919492518186" target="blank" >
                  <i className="fab fa-whatsapp whats"> </i>
                </a>
                <a href="https://www.linkedin.com/company/nh9-corp/about/" target="blank">
                  <i className="fab fa-linkedin-in insta"> </i>
                  </a>
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-3  col-xxl-4 ">
            <br></br>
              <img src={DigitalLogo} className="img-fluid mb-4" alt="Digital India logo" /><br></br><br></br><br></br>
              <img src={StartupLogo} className="img-fluid mb-4" alt="Start up India logo" />
            </div>
          </div>
        </div>
      </footer>
      <div className="bottomfooter mt-4">
        <p>
          Developed By:{" "}
          <a href="https://neologicx.com/">
            {" "}
            Neologicx Resources India Pvt. Ltd.
          </a>{" "}
        </p>
      </div>
    </>
  );
};

export default Footer;
