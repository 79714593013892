/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Contact from "./component/Contact";

import Header from "./component/Header";
import Product from "./component/Product";



// import pop from "./assets/images/jubli.jpg"

  

const Home = () => {
  
  return (
    <div>
      <Header />
      <Product />
      <Contact />
    </div>
  );
};

export default Home;
